import amazon_logo from 'images/amazon_logo.svg';
import bbva_logo from 'images/bbva_logo.svg';
import credit_andorra_logo from 'images/credit_andorra_logo.svg';
import mediamarkt_logo from 'images/mediamarkt_logo.svg';
import shopify_logo from 'images/shopify_logo.svg';

export const customers = [
  {
    name: 'Amazon',
    logo: amazon_logo,
    width: 200
  },
  {
    name: 'BBVA',
    logo: bbva_logo,
    width: 180
  },
  {
    name: 'Credit Andorra',
    logo: credit_andorra_logo,
    width: 350
  },
  {
    name: 'MediaMarkt',
    logo: mediamarkt_logo,
    width: 350
  },
  {
    name: 'Shopify',
    logo: shopify_logo,
    width: 250
  }
];
